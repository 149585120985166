.page-container {
  max-width: 1500px;
  margin: 0 auto;
}

.mining-page-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 160px 0;
  max-width: 1240px;
  margin: 0 auto;
  --image-reductionFactor: 1.2;
}

.crypto-board,
.gaming-board {
  position: relative;
  width: 50%;
  /* overflow: hidden; */
}

.crypto-board-content,
.gaming-board-content {
  position: absolute;
}

.crypto-board-content {
  top: 50%;
  transform: translate(-8%, -50%);
  left: calc(110px / var(--image-reductionFactor));
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  gap: calc(10px / var(--image-reductionFactor));
  padding: 112px 56px 112px 64px;
  width: 100%;
  background-image: url(../../assets/images/themes/gold/buttons/Background.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.gaming-board-content.mint-btn {
  left: 532px;
  bottom: 142.5px;
  width: calc(127px / var(--image-reductionFactor));
  height: calc(127px / var(--image-reductionFactor));
}

.gaming-board-content.mint-btn .mint-button {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  border: none; /* Remove any border */
  padding: 0; /* Remove any padding */
  margin: 0; /* Ensure no margin */
  cursor: pointer; /* Change cursor on hover */
  z-index: 999;
}

.gaming-board-content.user-percentage {
  top: 260px;
  left: 300px;
  z-index: 2;
  font-family: "RetroComputer", monospace;
  font-size: 14px;
  color: white;
}

.gaming-board-content.mining-animation {
  top: -4px;
  left: 245px;
}

.gaming-board-content.current-animal-image {
  left: 190px;
  bottom: -110px;
  width: calc(500px / var(--image-reductionFactor));
  height: calc(500px / var(--image-reductionFactor));
}

.gaming-board-content.current-animal-image img {
  width: 70%;
  height: 70%;
  object-fit: contain;
}

.crypto-board-content .connect-header,
.crypto-board-content .token-item {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}
.crypto-board-content .connect-header {
  justify-content: space-between;
  padding: 0px 12px;
}

.crypto-board-content .connect-header .text-line {
  font-size: calc(1em / var(--image-reductionFactor));
}

.crypto-board-content .token-item,
.crypto-board-content .add-token-btn {
  background-image: url(../../assets/images/themes/gold/buttons/TokenButton.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  padding: 0 calc(30px / var(--image-reductionFactor));
  height: calc(62px / var(--image-reductionFactor));
}

.crypto-board-content .token-item .coin-name,
.crypto-board-content .token-item .coin-balance,
.crypto-board-content .add-token-btn .btn-text {
  font-size: calc(1.2em / var(--image-reductionFactor));
  font-family: "RetroComputer", monospace;
  color: #ffffff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.crypto-board-content .token-item .coin-balance {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
  gap: 8px;
}

.crypto-board-content .add-token-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  cursor: pointer;
}

.mint-success-popup {
  @apply font-sans;
}

.token-list-btn.burn-container {
  width: 100px;
  display: flex;
  justify-content: center;
  justify-content: flex-end;
}

.token-list-btn.burn {
  background: linear-gradient(90.99deg, #722d31 0.18%, #e31f2b 100.18%);
  height: 30px;
  width: 100px;
  color: #ffffff;
  text-align: center;
  margin-left: 5px;
}

@media (max-width: 768px) {
  .mining-page-wrapper {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    padding: 160px 0 64px 0;
    margin: 0 auto;
    width: 360px;
  }

  .gaming-board {
    position: relative;
  }
  .crypto-board,
  .gaming-board {
    width: 100%;
    padding: 0px 12px;
    /* overflow: hidden; */
  }

  .crypto-board-content {
    position: static;
    transform: translate(0%, 0%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    gap: calc(10px / var(--image-reductionFactor));
    padding: 24px 24px 48px 24px;
    width: 100%;
  }

  .gaming-board-content.mint-btn {
    left: 84.5%;
    bottom: -2.8%;
    width: 16%;
    transform: translate(-50%, -50%);
  }

  .gaming-board-content.user-percentage {
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .gaming-board-content.mining-animation {
    top: 14.5%;
    left: 51%;
    transform: translate(-50%, -50%);
    width: 42%;
  }

  .gaming-board-content.current-animal-image {
    left: 32%;
    top: 33%;
    width: 55%;
  }
  .crypto-board-content .token-item,
  .crypto-board-content .add-token-btn {
    background-image: url(../../assets/images/themes/gold/buttons/TokenButton.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    padding: 4px 12px;
    height: auto;
  }

  .crypto-board-content .connect-header .text-line {
    font-size: 12px;
  }

  .crypto-board-content .token-item .coin-name,
  .crypto-board-content .token-item .coin-balance,
  .crypto-board-content .add-token-btn .btn-text {
    font-size: 12px;
  }

  .token-list-btn.burn-container {
    width: 80px;
  }

  .token-list-btn.burn {
    font-size: 12px;
    height: 30px;
    width: 80px;
    margin-left: 5px;
  }

  .mobile-header-mining {
    position: relative;
    width: 100%;
    top: 120px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}
