/* src/App.css */
@font-face {
  font-family: "RetroComputer";
  src: local("PressStart2P-Regular"),
    url("./fonts/PressStart2P-Regular.ttf") format("truetype"); /* Fallback for very old browsers */
  font-weight: normal;
  font-style: normal;
}

.App {
  background-color: #e1be8c; /* Replace with your color value */
  min-height: 100vh;
  /* Other styles */
  font-family: "RetroComputer", monospace;
}

.mining-page-container {
  width: 1150px;
  min-height: 100vh; /* Full viewport height */
}

.text-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 300px;
  margin-left: 160px;
  margin-top: 200px;
  margin-bottom: 30px;
  position: relative;
  bottom: 310px;
  z-index: 3;
}

.text-line {
  display: flex;
  justify-content: center;
  text-align: center;
  color: #ffffff; /* Adjust the color as needed */
  font-size: 1em; /* Adjust the size as needed */
  font-family: "RetroComputer", monospace;
  position: relative;
}

.token-list-background {
  background-image: url("./assets/images/themes/gold/buttons/Background.png"); /* Update the path */
  background-size: contain; /* or 'contain', depending on how you want to display it */
  background-repeat: no-repeat;
  background-position: left center;
  position: relative;
  width: 90%;
  height: 70vh;
  margin-left: 70px;
  bottom: 420px;
  z-index: 1;
}

.token-info-container {
  background-image: url("./assets/images/themes/gold/buttons/TokenButton.png");
  background-repeat: no-repeat;
  background-size: contain; /* Or 'contain' depending on your needs */
  background-position: left center;
  margin-left: 150px;
  position: relative;
  height: 50px;
  width: 510px;
  top: 180px;
  z-index: 3;
  /* Add more styling here */
}

.token-name {
  text-align: left;
  font-size: 1.2em;
  font-family: "RetroComputer", monospace;
  color: #ffffff; /* Retro white font color */
  white-space: nowrap; /* Prevent text from wrapping to the next line */
  overflow: hidden; /* Hide overflow */
  text-overflow: ellipsis; /* Add ellipsis for overflow text */
  margin-left: 20px;
  margin-top: 17px;
  position: absolute;
}

.token-balance .coin-image {
  width: 20px; /* Width of your image */
  height: 20px; /* Height of your image */
  background-image: url("./assets/images/themes/gold/buttons/SmallCoin.png");
  background-size: cover;
  background-repeat: no-repeat;
  margin-right: 5px; /* Adjust spacing between image and text */
}

.token-balance {
  text-align: right;
  position: relative;
  margin-right: 20px;
  font-size: 1.2em;
  font-family: "RetroComputer", monospace;
  color: #ffffff;
  white-space: nowrap; /* Prevent text from wrapping to the next line */
  overflow: hidden; /* Hide overflow */
  text-overflow: ellipsis; /* Add ellipsis for overflow text */
  padding-top: 17px;
}

.token-image {
  background-image: url("./assets/images/themes/gold/buttons/SmallCoin.png");
  background-repeat: no-repeat;
  background-size: contain; /* Or 'contain' depending on your needs */
  background-position: center;
}

.animal-console-background {
  background-image: url("./assets/images/themes/gold/buttons/GoldConsole.png");
  background-repeat: no-repeat;
  background-size: contain; /* Or 'contain' depending on your needs */
  background-position: right;
  position: absolute;
  width: 80%;
  height: 100vh;
  z-index: 4;
  margin-left: 200px;
  top: 100px;
}

.mining-animation-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  max-width: 300px;
  margin: auto;
  bottom: 1100px;
  z-index: 5;
  margin-top: 10px;
  margin-right: 35px;
}

.mining-animation-container img {
  position: absolute;
  top: 20%;
  left: 10%;
  width: 400px;
  height: auto; /* Maintain aspect ratio */
}

.user-percentage-container {
  display: flex;
  flex: 0 1 auto; /* do not grow, allow shrink, basis is auto */
  justify-content: center;
  align-items: center;
  font-family: "RetroComputer", monospace;
  color: white;
  position: relative;
  max-width: 97px; /* Adjust the width as needed */
  margin-top: 5px;
  margin-left: 1000px;
  margin-right: 20px;
  bottom: 775px;
  z-index: 5;
}

.animal-image-container {
  display: flex;
  background-color: transparent;
  position: relative;
  justify-content: center;
  align-items: center;
  padding-top: -20px;
  width: 500px; /* Adjust the width as needed */
  height: 500px;
  margin-top: 20px;
  margin-left: 940px;
  margin-right: auto;
  bottom: 700px; /* Adjust the space between the images */
  z-index: 6;
}

.animal-image {
  background-color: transparent !important;
  align-items: center;
  position: relative;
  width: 800px; /* Adjust the width as needed */
  height: 500px;
  margin-right: 280px;
  bottom: 190px;
}

.mint-button-container {
  /* Style your container */
  text-align: center;
  position: relative;
  margin: 10px 0;
  margin-top: -70px;
  width: 150px;
  height: 150px;
  margin-left: 1242px;
  bottom: 545px;
  z-index: 5;
}

.mint-button {
  border: none;
  background: transparent;
  position: relative;
  color: #fff;
  cursor: pointer;
  position: relative;
  width: 125px;
  height: 120px;
}

.start-stop-button {
  background: transparent;
  position: relative;
  cursor: pointer;
  position: relative;
  width: 60px;
  height: 30px;
  top: 165px;
  margin-left: 580px;
  z-index: 4;
}

.mint-button.enabled {
  background-image: url("./assets/images/themes/gold/buttons/MintEnabled2.png"); /* Green when enabled */
  cursor: pointer;
}

.mint-button.disabled {
  background-image: url("./assets/images/themes/gold/buttons/MintDisabled2.png"); /* Greyed out when disabled */
  cursor: default;
}

.header-userbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.header-userbar-btn {
  height: 48px;
  background: #9b8d47;
  padding: 2px 24px 0px 24px;
  font-family: "RetroComputer", monospace;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.header-userbar-btn.burn {
  background: linear-gradient(90.99deg, #722d31 0.18%, #e31f2b 100.18%);
}

.header-userbar-profile {
  width: 50px;
  height: 50px;
}

.react-toaster-container * {
 @apply !font-sans;
}