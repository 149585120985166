.about {
  font-family: "RetroComputer", monospace;
}

.background {
  background-image: url("../../assets/images/about/Background.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  position: relative;
  width: 100%;
  height: 100%;
}

.frame-69-item {
  width: 100%;
  background: #E1BE8C;
}

