.connect-wallet-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center items horizontally in the container */
  justify-content: center; /* Center items vertically in the container */
  text-align: center;
  height: auto; /* Adjust as needed */
  width: 550px; /* Take the full width of the parent */
  margin: 0 auto; /* Center the container within its parent */
  padding-top: 10px;
}

.connect-wallet-container img {
  max-width: 550px; /* Ensure the image is not bigger than the container */
  height: auto;
  display: block; /* Makes margin auto work for horizontal centering */
  margin-left: auto; /* Center the image horizontally */
  margin-right: auto; /* Center the image horizontally */
}

/* Mobile styles */
@media (max-width: 600px) {
  .connect-wallet-container {
    width: 100%; /* Allow the container to take up more of the screen on mobile */
    padding-top: 50px; /* Reduce the padding on top for mobile */
  }

  .connect-wallet-container img {
    max-width: 90%; /* Allow the image to scale down further if needed */
  }
}
  