/* src/components/Header/Header.css */

.header {
  position: fixed;
  z-index: 10;
  width: 100%;
}

.header-container {
  background-image: url("../../assets/images/themes/gold/buttons/Header.png");
  background-size: 100% 110%;
  background-repeat: no-repeat;
  background-position: center;
  max-width: 1500px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  height: 100px;
}

.header nav {
  display: flex;
  justify-content: space-between; /* This will space the links evenly across the header */
  align-items: center; /* This will vertically align them in the center if they have different heights */
  padding: 0 120px; /* Add padding on the sides if needed */
  padding-top: 10px;
}

.header-connect-button {
  display: flex;
  justify-content: space-between; /* This will space the links evenly across the header */
  align-items: center; /* This will vertically align them in the center if they have different heights */
}

.mobile-header {
  /* Styles for your mobile header */
  position: relative;
  /* padding: auto 60px; */
}

/* Hamburger Menu */
.hamburger-menu {
  cursor: pointer;
  /* padding: 15px; */
  position: absolute;
  top: -18px;
  right: 0px;
  z-index: 3; /* Ensure it's above other content */
}

/* Hamburger Bars */
.bar {
  display: block;
  width: 25px;
  height: 3px;
  margin: 5px;
  background-color: #333;
  transition: all 0.3s ease;
}

/* Mobile Navigation */
.mobile-nav {
  height: 100%;
  background: #3a3a3a;
  width: 250px;
  position: fixed;
  top: 0;
  right: -250px; /* Start offscreen to the right */
  transition: transform 0.3s ease;
  z-index: 1;
}

.mobile-nav ul {
  list-style-type: none;
  padding-top: 96px;
  margin: 0;
  display: flex;
  flex-direction: column; /* Stack links vertically */
  align-items: start; /* Align links to the start */
  height: 100%;
  justify-content: flex-start; /* Center links vertically */
}

.mobile-nav li {
  width: 100%;
  padding: 6px 0; /* Add padding for tap targets */
}

.mobile-nav li a {
  display: block;
  padding: 0 20px; /* Add padding inside links */
  color: #ffffff;
  text-decoration: none;
}

/* Class to toggle the mobile menu */
.show-mobile-nav {
  transform: translateX(-250px); /* Slide in the menu */
}

.nav-link-mobile {
  font-family: "RetroComputer", monospace;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.icon {
  height: 22px;
  width: 22px;
}

@media (max-width: 1240px) {
  .header nav {
    padding: 0 40px; /* Add padding on the sides if needed */
    padding-top: 15px;
  }
}

@media (max-width: 1080px) {
}

@media (max-width: 768px) {
  /* Ensure this only applies to mobile sizes */
  .hamburger-menu {
    display: flex; /* Only show the hamburger on mobile */
  }

  .desktop-nav {
    display: none; /* Hide desktop navigation on mobile */
  }
}
