* {
  font-family: "RetroComputer", monospace;
}

.nft-details-bg {
  /* background: url(../../../assets/images//marketplace/modal-bg.png);
  background-repeat: no-repeat;
  background-position: center right; */
  background: #BE9651;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.price-bg{
  background: #E1BE8C;
  border-radius: 10px;
}

.success-popup {
  @apply !font-sans;
}

@media (max-width: 768px) {
  .nft-details-bg {
    background: #BE9651;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
}